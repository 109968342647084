import React from "react";
import Layout from "../components/Layout";
import firebase from "firebase/app";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { navigate } from "gatsby";
import {
  Profile,
  User,
  Customer,
  ProfileClass,
  CustomerClass
} from "../interfaces/account";
import { StripeProvider, Elements } from "react-stripe-elements";
import Checkout from "../components/Checkout";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { toggleModal } from "../state/actions/components";
import Loading from "../components/Loading";

class Account extends React.Component<any, State> {
  state = {
    user: null,
    customer: null,
    profile: null,
    isLoading: true,
    loadingModal: false,
    alert: false,
    isActive: false,
    message: "",
    open: false,
    error: false,
    errorMsg: "An Error Has Occured. Try Again In A Few Moments.",
    isUpdating: false
  };

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  removeSource = async () => {
    const config = {
      isOpen: true,
      confirmAction: false,
      content: null,
      defaultState: false,
      isLoading: true,
      labelOne: null,
      labelTwo: null,
      onlyClose: false,
      showButtons: false,
      toggleModal: null,
      title: "Updating Account ..."
    };
    this.props.toggleModal(config);
    const removeSource = firebase
      .functions()
      .httpsCallable(process.env.REMOVE_SOURCE);
    const customerId = this.state.customer.customerId;
    const source = this.state.profile.sources.data[0].id;

    try {
      const _response = await removeSource({ customerId, source });
      const response = _response.data;
      const message = response.message;
      const alert = true;
      this.setState({ message, alert }, () => {
        const _user = this.state.user;
        this.getCustomer(_user);
        this.props.toggleModal({ defaultState: true });
      });
    } catch (error) {
      config.title =
        "An error occured while updating. Please try again in a few moments.";
      config.labelTwo = "Close";
      config.onlyClose = true;
      config.showButtons = true;
      this.props.toggleModal(config);
    }
  };

  updateSource = (token: any, customer) => {
    this.setState(
      { error: false, isUpdating: true, message: "Updating Account ..." },
      async () => {
        const updateSource = firebase
          .functions()
          .httpsCallable(process.env.UPDATE_STRIPE_CUSTOMER);
        const customerId = customer.customerId;
        const source = token.token.id;
        try {
          const _response = await updateSource({ customerId, source });
          const response = _response.data;
          const message = response.message;
          const alert = true;
          const isActive = true;
          this.setState(
            { message, alert, isActive, isUpdating: false, open: false },
            () => {
              const _user = this.state.user;
              this.getCustomer(_user);
            }
          );
        } catch (error) {
          const message = "An Error Has Occured. Try Again In A Few Moments.";
          const alert = true;
          const open = true;
          this.setState({ message, alert, open, isUpdating: false }, () => {
            const _user = this.state.user;
            this.getCustomer(_user);
          });
        }
      }
    );
  };

  unsubscribe = async () => {
    const config = {
      isOpen: true,
      confirmAction: false,
      content: null,
      defaultState: false,
      isLoading: true,
      labelOne: null,
      labelTwo: null,
      onlyClose: false,
      showButtons: false,
      toggleModal: null,
      title: "Updating Account ..."
    };
    this.props.toggleModal(config);
    const unsubscribeCustomer = firebase
      .functions()
      .httpsCallable(process.env.UNSUBSCRIBE_CUSTOMER);
    const subId = this.state.profile.subscriptions.data[0].id;
    try {
      const _response = await unsubscribeCustomer({ subId });
      this.props.toggleModal({ defaultState: true });
      const _user = this.state.user;
      this.getCustomer(_user);
    } catch (error) {
      config.title =
        "An error occured while updating. Please try again in a few moments.";
      config.labelTwo = "Close";
      config.onlyClose = true;
      config.showButtons = true;
      this.props.toggleModal(config);
    }
  };

  subscribe = async () => {
    const config = {
      isOpen: true,
      confirmAction: false,
      content: null,
      defaultState: false,
      isLoading: true,
      labelOne: null,
      labelTwo: null,
      onlyClose: false,
      showButtons: false,
      toggleModal: null,
      title: "Updating Account ..."
    };
    this.props.toggleModal(config);
    const subscribeCustomer = firebase
      .functions()
      .httpsCallable(process.env.SUBSCRIBE_CUSTOMER);
    const customerId = this.state.customer.customerId;
    try {
      const _response = await subscribeCustomer({ customerId });
      const response = _response.data;
      const message = response.message;
      const alert = true;
      this.setState({ message, alert }, () => {
        const _user = this.state.user;
        this.getCustomer(_user);
        this.props.toggleModal({ defaultState: true });
      });
    } catch (error) {
      const message = "An Error Has Occured. Try Again In A Few Moments.";
      const alert = true;
      const isActive = true;
      this.setState({ message, alert, isActive }, () => {
        config.title =
          "An error occured while updating. Please try again in a few moments.";
        config.labelTwo = "Close";
        config.onlyClose = true;
        config.showButtons = true;
        this.props.toggleModal(config);
      });
    }
  };

  getCustomer = async (_user: any) => {
    const config = {
      isOpen: true,
      confirmAction: false,
      content: null,
      defaultState: false,
      isLoading: true,
      labelOne: null,
      labelTwo: null,
      onlyClose: false,
      showButtons: false,
      toggleModal: null,
      title: "Updating Account ..."
    };
    this.props.toggleModal(config);
    const userId = _user.uid;
    const db = firebase.firestore().collection(process.env.USERS);
    const getCustomer = firebase
      .functions()
      .httpsCallable(process.env.GET_STRIPE_CUSTOMER);
    try {
      const _customer = await db.doc(userId).get();
      const customer = _customer.data();
      const _profile = await getCustomer({ customerId: customer.customerId });
      const profile: Profile = _profile.data;
      const isLoading = false;
      this.setState({ user: _user, customer, profile, isLoading }, ()=> {
        this.props.toggleModal({ defaultState: true });
      });
    } catch (error) {
      config.title =
        "An error occured while updating. Please try again in a few moments.";
      config.labelTwo = "Close";
      config.onlyClose = true;
      config.showButtons = true;
      this.props.toggleModal(config);
    }
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async _user => {
      if (_user) {
        // User is signed in.
        this.getCustomer(_user);
      } else {
        navigate("/signin");
      }
    });
  }

  render() {
    const {
      isLoading,
      isUpdating,
      error,
      message,
      customer,
      profile,
      open
    } = this.state;
    if (isLoading) {
      const content = (
        <div className="account">
          <Typography variant="h4">Loading Account</Typography>
          <CircularProgress className="loading" color="secondary" />
        </div>
      );
      return (
        <Layout
          components={[{ columns: 12, content, className: "accont-info" }]}
        />
      );
    } else {
      const user: CustomerClass = customer;
      const hasExp = user.productExp > 0;
      const subExp: string =
        user.productExp > 0
          ? new Date(user.productExp * 1000).toLocaleDateString()
          : "";
      const hasSource = profile.sources.data.length > 0;
      const isActive = profile.subscriptions.data.length > 0;
      const cardType = hasSource ? profile.sources.data[0].brand : null;
      const lastFour = hasSource ? profile.sources.data[0].last4 : null;

      const content = (
        <Paper className="account">
          <Typography variant="h3" className="account__title">
            Account
          </Typography>
          <div className="account__view">
            <Card>
              <CardContent className="account__card">
                <Typography variant="h5">Account Information</Typography>
                {hasExp && isActive ? (
                  <Typography>{`Subscription Renews On Or After ${subExp}`}</Typography>
                ) : null}
                {hasExp && !isActive ? (
                  <Typography>{`Subscription Ends On Or After ${subExp}`}</Typography>
                ) : null}
                {hasSource ? (
                  <Typography variant="h6">
                    {cardType}: ending with {lastFour}
                  </Typography>
                ) : (
                  <Typography variant="h6">No Card Available</Typography>
                )}
                {!isActive && hasSource ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.removeSource()}
                  >
                    Remove Card
                  </Button>
                ) : null}
              </CardContent>
            </Card>
            <div className="account__actions">
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleOpen()}
              >
                Update Card
              </Button>
              {isActive ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.unsubscribe()}
                >
                  Unsubscribe
                </Button>
              ) : null}
              {hasSource && !isActive ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.subscribe()}
                >
                  Resubscribe
                </Button>
              ) : null}
            </div>
          </div>
          <Dialog
            open={open}
            onClose={() => this.handleClose()}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {isUpdating ? `${message}` : "Update Card Information"}
            </DialogTitle>
            <DialogContent>
              {isUpdating ? (
                <Loading title={""} />
              ) : (
                <StripeProvider apiKey={process.env.STRIPE_KEY}>
                  <Elements>
                    <Checkout
                      buttonText={"Update Card"}
                      showTerms={false}
                      updateSource={this.updateSource}
                      customer={customer}
                      isModal={true}
                    />
                  </Elements>
                </StripeProvider>
              )}
            </DialogContent>
          </Dialog>
        </Paper>
      );
      return (
        <Layout
          components={[{ columns: 12, content, className: "accont-info" }]}
          authRequired={false}
        />
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  modal: state.components.modalState
});

export default connect(
  mapStateToProps,
  { toggleModal }
)(Account);

interface State {
  user: null | User | firebase.User;
  customer: null | Customer | firebase.firestore.DocumentData;
  profile: null | Profile;
  isLoading: boolean;
  loadingModal: boolean;
  alert: boolean;
  isActive: boolean;
  message: string;
  open: boolean;
  error: boolean;
  isUpdating: boolean;
}
