import React from "react";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement
} from "react-stripe-elements";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import firebase from "firebase/app";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loading from "../components/Loading";
import { navigate } from "gatsby";
import { connect } from 'react-redux';
import { toggleContact } from '../state/actions/components';
import domains from "disposable-email-domains";
import "paymentfont/css/paymentfont.min.css";

class Checkout extends React.Component<Props, any> {
  state = {
    name: "",
    email: "",
    confEmail: "",
    hasError: false,
    errorMsg: "",
    agreedToTerms: false,
    isOpen: false,
    statusWindow: false,
    isLoading: false,
    complete: false
  };

  componentWillMount() {
    const isModal = this.props.isModal;
    if (isModal) {
      this.setState({ agreedToTerms: true });
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  openStatus = () => {
    this.setState({ statusWindow: true });
  };

  closeStatus = () => {
    this.setState({ statusWindow: false });
  };

  handleSubmit = async ev => {
    ev.preventDefault();
    const customer = this.props.customer;
    const updateSource = this.props.updateSource;
    const isSourceUpdate = updateSource !== null && updateSource !== undefined;
    const email = this.state.email;
    const confEmail = this.state.confEmail;
    const emailsMatch = email === confEmail;
    const domainCheck = (domain: string) => {
      const isInvalid = email.includes(domain);
      if (!isInvalid) return false;
      return true;
    };
    const name = this.state.name;
    const functions = firebase.functions();
    const createTempUser = functions.httpsCallable(
      process.env.CREATE_TEMP_USER
    );
    const auth = firebase.auth();
    const token = await this.props.stripe.createToken({ type: "card", name, email });
    const error = token.error;
    const emailIsValid = this.validateEmail(email) && email.length > 0 && domains.some(domainCheck) === false;
    const nameIsValid = name.length > 0;
    const cardError = error !== undefined;
    const agreedToTerms = this.state.agreedToTerms;
    const hasWindow = typeof window !== undefined;
    let errorMsg = "Please Verify Your Information Is Correct";
    let hasError = false;
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: process.env.SITE,
      // This must be true.
      handleCodeInApp: true
    };
    

    if (hasWindow) {
      if (!agreedToTerms) {
        errorMsg = "Please read and accept the terms of service";
        hasError = true;
        this.setState({ hasError, errorMsg });
      }
      if (!emailIsValid) {
        errorMsg = "The email address is invalid";
        hasError = true;
        this.setState({ hasError, errorMsg });
      }

      if (!emailsMatch) {
        errorMsg = "Emails do not match";
        hasError = true;
        this.setState({ hasError, errorMsg });
      }

      if (!nameIsValid) {
        errorMsg = "Please verify the name provided is correct";
        hasError = true;
        this.setState({ hasError, errorMsg });
      }

      if (cardError) {
        hasError = true;
        this.setState({ hasError, errorMsg: error.message });
      }

      if (cardError) {
        hasError = true;
        this.setState({ hasError, errorMsg: error.message });
      }

      if (
        !cardError &&
        emailIsValid &&
        nameIsValid &&
        emailsMatch &&
        agreedToTerms
      ) {
        if (isSourceUpdate) {
          hasError = false;
          this.setState({ hasError, errorMsg: "" }, () => {
            updateSource(token, customer);
          });
        } else {
          hasError = false;
          this.setState(
            { hasError, errorMsg: "", isLoading: true, statusWindow: true },
            async () => {

              try {
                const _tempUserRes = await createTempUser({
                  email,
                  token: token.token.id
                });
                const tempUserRes = _tempUserRes.data;
                const sendEmail = await auth.sendSignInLinkToEmail(email, actionCodeSettings);
                localStorage.setItem("emailForSignIn", email);
                this.setState({ complete: true, isLoading: false });
              } catch (error) {
                this.setState({ 
                  hasError: true,
                  errorMsg: 'This Email Already Exists. Try Signing In Instead',
                  isLoading: false,
                  complete: false
                 });
              }
            }
          );
        }
      }
    }
  };

  updateField(ev, field) {
    const value = ev.target.value;
    this.setState({ [field]: value });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const buttonText = this.props.buttonText;
    const hasBtnText = buttonText !== null && buttonText !== undefined;
    const includeTos = true;
    const isComplete = this.state.complete;
    const hasError = this.state.hasError;
    const errorMsg = this.state.errorMsg;
    const isLoading = this.state.isLoading;
    const isWorking = !isComplete && !hasError && isLoading;
    const isModal = this.props.isModal;

    if (isModal && isLoading) {
      return <Loading title="Updating Account ..." />;
    }

    if (isModal && hasError) {
      return (
        <div>
          <DialogTitle>{errorMsg}</DialogTitle>
          <Button onClick={() => this.props.toggleContact(true)}>Contact Support</Button>
        </div>
      )
    }

    return (
      <form className="payment__form">
        <div className="payment__types">
          <i className="pf pf-american-express" />
          <i className="pf pf-mastercard-alt" />
          <i className="pf pf-visa" />
          <i className="pf pf-discover" />
          <i className="pf pf-stripe" />
        </div>
        {this.state.hasError ? (
          <Typography
            component="h1"
            className="payment__error"
            color="error"
          >
            {this.state.errorMsg}
          </Typography>
        ) : null}
        <div className="mdl-textfield mdl-js-textfield">
          <Typography>Name</Typography>
          <input
            className="mdl-textfield__input"
            type="text"
            id="sample1"
            name="name"
            onChange={ev => this.updateField(ev, "name")}
            onKeyUp={ev => this.updateField(ev, "name")}
          />
        </div>
        <div className="mdl-textfield mdl-js-textfield">
          <Typography>Email</Typography>
          <input
            className="mdl-textfield__input"
            type="email"
            id="sample1"
            name="name"
            onChange={ev => this.updateField(ev, "email")}
            onKeyUp={ev => this.updateField(ev, "email")}
          />
        </div>
        <div className="mdl-textfield mdl-js-textfield">
          <Typography>Confirm Email</Typography>
          <input
            className="mdl-textfield__input"
            type="email"
            id="sample1"
            name="name"
            onChange={ev => this.updateField(ev, "confEmail")}
            onKeyUp={ev => this.updateField(ev, "confEmail")}
          />
        </div>
        <div className="mdl-textfield mdl-js-textfield">
          <Typography>Card Number</Typography>
          <CardNumberElement className="mdl-textfield__input" />
        </div>
        <div className="mdl-textfield mdl-js-textfield">
          <Typography>Card Expiration</Typography>
          <CardExpiryElement className="mdl-textfield__input" />
        </div>
        <div className="mdl-textfield mdl-js-textfield">
          <Typography>Card CVC</Typography>
          <CardCVCElement className="mdl-textfield__input" />
        </div>
        <div className="mdl-textfield mdl-js-textfield">
          <Typography>Billing Postal Code</Typography>
          <PostalCodeElement className="mdl-textfield__input" />
        </div>
        {!isModal ? (
          <div className="payment__tos">
            <Checkbox
              checked={this.state.agreedToTerms}
              onChange={this.handleChange("agreedToTerms")}
              value="checkedA"
            />
            <Button variant="flat" onClick={() => this.handleOpen()}>
              I have reviewed and accepted the terms of service
            </Button>
          </div>
        ) : null}
        <Button
          color="primary"
          variant="contained"
          onClick={ev => this.handleSubmit(ev)}
        >
          {hasBtnText ? buttonText : "Purchase Subscription"}
        </Button>
        <Dialog open={this.state.isOpen} onClose={() => this.handleClose()}>
          <DialogContent>{this.props.tos}</DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleOpen()}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.statusWindow}>
          <DialogContent>
            {isWorking ? <Loading title="Creating Account ..." /> : null}
            {isComplete ? (
              <div>
                <DialogTitle>
                  Account Created. Check Your Email to Sign-In
                </DialogTitle>
                <Button variant="flat" onClick={() => navigate("/")}>
                  Click Here To Return Home
                </Button>
              </div>
            ) : null}
            {hasError ? (
              <div>
                <DialogTitle>{errorMsg}</DialogTitle>
                <Button onClick={() => this.props.toggleContact(true)}>
                  Contact Support
                </Button>
              </div>
            ) : null}
          </DialogContent>
          {hasError ? (
            <DialogActions>
              <Button onClick={() => this.closeStatus()}>Close</Button>
            </DialogActions>
          ) : null}
        </Dialog>
      </form>
    );
  }
}

interface Props {
  stripe: any;
  buttonText: any | null;
  showTerms: boolean | null;
  updateSource: any | null;
  customer: any | null;
  tos: any;
  contact: boolean;
  toggleContact: (bool: boolean) => void;
  isModal: boolean | undefined;
}

interface State {
  userData: UserData | null;
  name: string | any;
  email: string | any;
}

interface UserData {
  customerId: string;
  email: string;
  id: string;
}

const mapStateToProps = (state: any) => ({
  contact: state.components.contact
});

export default connect(mapStateToProps, { toggleContact })(injectStripe(Checkout));
